import React, { Component } from 'react';
import HelmetEx from '../../components/helmet-ex';
import Layout from '../../components/layout';
import ServicePagePortal from '../../components/service-page-portal';

class InstallationService extends Component {
    render() {
        return (
            <Layout>
                <div className="installation-service-page">
                    <HelmetEx>
                        <div metadata="title">Window Covering Automation & Installation | Elmwood Park NJ</div>
                        <div metadata="keywords">Window,covering,Automation,Installation,Berge,County,NJ</div>
                        <div metadata="description">
                            Let us do the measuring and installation and make your life easy!
                            Our experienced staff is fully trained to properly measure and
                            install virtually any window covering.
                        </div>
                    </HelmetEx>
                    <section className="header">
                        <div className="container">
                            <div className="content">
                                <div className="text">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h1>INSTALLATION SERVICE</h1>
                                            <h3>Let us do the measuring and installation and make your life easy</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="below-navigation-section">
                        <div className="container">
                            <ServicePagePortal activePath={"/services/installation-service"}>
                                <section>
                                    <h4>WINDOW COVERING MEASURING AND INSTALLATION</h4>
                                    <p className="first-child-p pt-4 pt-lg-0">
                                        Let us do the measuring and installation and make your life easy!
                                        Our experienced staff is fully trained to properly measure and install virtually any window covering.
                                    </p>
                                </section>
                                <section>
                                    <h4>WINDOW COVERING MOTORIZATION</h4>
                                    <p className="first-child-p pt-4 pt-lg-0">
                                        Rely on our motorization experts to design the best automated solution for your window coverings.
                                    </p>
                                </section>
                            </ServicePagePortal>
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

export default InstallationService;